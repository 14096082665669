import { format } from 'date-fns';
import { PHONE_NUMBER_UK_MOBILE } from 'jl-design-system/form/fields';
import replaceStringVars from 'jl-design-system/utils/string/replaceStringVars';

import CLICK_AND_COLLECT_DATES from './clickAndCollectDates';
import USER_EMAIL_ADDRESS from './userEmailAddress';
import GIFT_MESSAGE from './giftMessage';
import GIFT_RECEIPT_CHECKBOX from './giftReceiptCheckbox';
import getInternationalPhoneNumberConfig from '../../lazy/internationalPhoneNumberConfig';

export const COLLECTOR_DETAILS_FORM_ID = 'collectorDetailsForm';

export default function getCollectorDetailsFormConfig({
  containerClassName,
  dates,
  daysToShowPerPage,
  disabled,
  focusOnSelectedDayMessage,
  instruction,
  isCollectPlus,
  isGiftReceiptSelected = false,
  maxPagesToShow,
  selectedDay,
  selectedDayMessageClassName,
}: {
  containerClassName?: string;
  dates?: any;
  daysToShowPerPage?: any;
  disabled?: boolean;
  focusOnSelectedDayMessage?: boolean;
  instruction?: string;
  isCollectPlus?: boolean;
  isGiftReceiptSelected?: boolean;
  maxPagesToShow?: any;
  selectedDay?: any;
  selectedDayMessageClassName?: string;
}) {
  const PHONE_NUMBER = getInternationalPhoneNumberConfig({
    instruction: 'A UK mobile number will allow us to send a text when the order is ready to collect',
  });

  const dayPicker = dates ? {
    ...CLICK_AND_COLLECT_DATES,
    required: !selectedDay,
    dates,
    daysToShowPerPage,
    maxPagesToShow,
    containerClassName,
    selectedDay,
    selectedDayMessageTitle: selectedDay ? replaceStringVars('Collect from ${time} on ${date}', {
      time: format(selectedDay, 'h:mma'),
    }) : undefined,
    selectedDayMessageClassName,
    instruction,
    focusOnSelectedDayMessage,
    disabled,
  } : undefined;

  const PHONE_NUMBER_FIELD = isCollectPlus ? {
    ...PHONE_NUMBER_UK_MOBILE,
    instruction: '',
  } : PHONE_NUMBER;

  return {
    id: COLLECTOR_DETAILS_FORM_ID,
    fields: [
      dayPicker,
      USER_EMAIL_ADDRESS,
      PHONE_NUMBER_FIELD,
      {
        ...GIFT_RECEIPT_CHECKBOX,
        checked: isGiftReceiptSelected,
        giftFormId: 'giftReceipt',
      },
      ...(isGiftReceiptSelected ? [{ ...GIFT_MESSAGE, giftFormId: 'giftReceipt' }] : []),
    ],
    submitButton: 'Continue to payment',
  };
}
