// Please note that to enable features or switches they must
// also be added to features.yml in the checkout code base
export default {
  ALLOW_MY_JL_SIGN_UPS: 'join_my_jl',
  APPLE_PAY_WITHOUT_SAVED_CARD: 'apple_pay_without_saved_card',
  CAMPAIGN_BANNER: 'campaign_banner',
  CONFIRMATION_EMAIL_DELAY: 'confirmation_email_delay',
  DELIVERY_V3: 'delivery_v3',
  DELIVERY_COLLECTION_RADIO_BUTTONS: 'delivery_collection_radio_buttons',
  DISABLE_APPLE_PAY: 'disable_apple_pay',
  DISABLE_CLEARPAY: 'disable_clearpay',
  DISABLE_GOOGLE_PAY: 'disable_google_pay',
  DISABLE_KLARNA: 'disable_klarna',
  DISABLE_KOUNT: 'disable_kount',
  DISABLE_PAYPAL: 'disable_paypal',
  DISABLE_YOTI: 'disable_yoti',
  ENABLE_PAYPAL_EXPRESS: 'enable_paypal_express',
  FASTER_COLLECTION_PAGE: 'faster_collection_page',
  FIXED_BASKET_FOOTER: 'fixed_basket_footer',
  FLOWERS_PROPOSITION_DISABLED: 'flowers_proposition_disabled',
  FORCE_CUSTOMER_APP_TYPE: 'force_customer_app_type',
  FREE_CLICK_COLLECT: 'free_click_collect',
  KOUNT_MASTERCARD: 'kount_mastercard',
  LOG_ANALYTICS: 'logAnalytics',
  MEMBERS_WEEK_MESSAGING: 'members_week_messaging',
  NEW_ADDRESS_BOOK_LAYOUT: 'new_address_book_layout',
  OUTLET_DELIVERY_DATES: 'outlet_delivery_dates',
  PAYMENT_BUTTON_SECURE_ICON: 'payment_button_secure_icon',
  PAYMENT_PAGE_BANNER_ENABLED: 'payment_page_banner_enabled',
  PAYPAL_CLIENT_TOKEN_OVERRIDE: 'payPalClientTokenOverride',
  PREFERRED_DELIVERY_CHOICE: 'preferred_delivery_choice',
  PRIORITISE_NEW_CARD: 'prioritise_new_card',
  RAVELINJS_DISABLED: 'ravelinjs_disabled',
  RETRY_COLLECTION_DATES: 'retry_collection_dates',
  SHOW_CALL_CENTRE_NUMBER_ON_3DS_ERROR: 'show_call_centre_number_on_3ds_error',
  SHOW_RECOMMENDATIONS: 'show_recommendations',
  STRIPE_CHECKOUT_ENABLED: 'stripe_checkout_enabled',
  TEST_CRYPTOGRAM_IN_INTEGRATION: 'test_cryptogram_in_integration',
  TRA_EXTRA_VISA: 'tra_extra_visa',
  TRA_VISA_BINS: 'tra_visa_bins',
  USE_LOCAL_PAYPAL_SANDBOX_OVERRIDE: 'use_local_paypal_sandbox_override',
  USE_REAL_CLEARPAY: 'use_real_clearpay',
  USE_REAL_KLARNA: 'use_real_klarna',
  USE_REAL_LOQATE_OVERRIDE: 'use_real_loqate_override',
  USE_STUBBED_NEWRELIC: 'use_stubbed_newrelic',
};
